import React,{useState} from 'react';
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';
import GuideMain from './components/GuideMain';


const Guide = (props) =>{

    return <div className="Guide">
      <Header></Header>
      <Banner height='317' img='https://oss.5rs.me/oss/uploadfe/png/cbe7b52b04dfcb150a0585bec77cdda0.png?_=7802'></Banner>
      <GuideMain></GuideMain>
      <Footer></Footer>
    </div>
}

export default Guide;